import {
  LOGIN_SUCCESS,
  REGISTER_SUCCESS,
  USER_PROFILE_SUCCESS,
  REGISTER_ERROR,
  REGISTER,
  LOGIN,
  UPDATE_USER_PROFILE,
  UPDATE_USER_PROFILE_SUCCESS,
  LOGOUT,
  USER_PROFILE,
  UPDATE_USER_PROFILE_ERROR,
  UPDATE_USER_STATE,
  UPLOAD_USER_PROFILE_PIC,
} from "../constants";

// Initial State
const initialState = {
  isLogin: false,
  isLoading: false,
  userDetails: {},
  token: null,
  errorMessage: null,
  successMessage: null,
};

// Reducers (Modifies The State And Returns A New State)
const UsersReducer = (state = initialState, action) => {
  switch (action.type) {
    // Login
    case REGISTER:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
        token: null,
      };
    case UPDATE_USER_PROFILE:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
        successMessage: null,
      };
    case UPLOAD_USER_PROFILE_PIC:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
        successMessage: null,
      };
    case LOGIN:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
        token: null,
      };
    case USER_PROFILE:
      return {
        ...state,
        isLoading: true,
      };
    case LOGOUT:
      return {
        ...state,
        isLogin: false,
        isLoading: false,
        errorMessage: null,
        token: null,
        logout: true,
        userDetails: {},
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLogin: true,
        isLoading: false,
        errorMessage: null,
        token: action.data.token,
        logout: false,
      };
    case REGISTER_ERROR:
      return {
        ...state,
        isLoading: false,
        token: null,
        errorMessage: action.data,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        isLogin: true,
        isLoading: false,
        errorMessage: null,
        token: action.data.token,
        logout: false,
      };
    case UPDATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        successMessage: "PROFILE_UPDATED_SUCCESSFULLY",
        userDetails: { ...state.userDetails, ...action.data },
      };
    case UPDATE_USER_PROFILE_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: "PROFILE_UPDATED_FAILED",
        successMessage: null,
      };
    case USER_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        userDetails: action.data,
      };
    case UPDATE_USER_STATE:
      return {
        ...state,
        ...action.data,
      };
    // Default
    default:
      return { ...state };
  }
};

// Exports
export default UsersReducer;
