import React, { useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import useTranslation from "next-translate/useTranslation";
import { doRegister, doLogin } from "./../../../store/actions/Users";
import Snackbar from "../../components/SnackBar";
import SignUpForm from "./SignUpForm";
import SignInForm from "./SignInForm";
import useStyles from "./style";

export default function AuthModalContainer() {
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  const classes = useStyles();
  const usersData = useSelector((state) => {
    return state.UsersReducer;
  });

  const [type, setType] = useState("login");

  const handleSubmit = (values) => {
    if (type === "register") {
      dispatch(doRegister(values));
    } else if (type === "login") {
      dispatch(doLogin(values));
    }
  };

  const handleClick = (event, t) => {
    event.preventDefault();
    setType(t);
  };

  return (
    <Container maxWidth="sm">
      <Box my={4}>
        <div className={classes.paper}>
          {type === "login" ? (
            <SignInForm
              handleSubmit={handleSubmit}
              isLoading={usersData.isLoading}
            />
          ) : null}
          {type === "register" ? (
            <SignUpForm
              handleSubmit={handleSubmit}
              isLoading={usersData.isLoading}
            />
          ) : null}
        </div>
        <Grid container>
          {type === "register" ? (
            <Grid item xs>
              <Link
                href="#"
                onClick={(event) => handleClick(event, "login")}
                variant="body2"
              >
                {t("login")}
              </Link>
            </Grid>
          ) : null}
          {type === "login" ? (
            <Grid item>
              <Link
                href="#"
                onClick={(event) => handleClick(event, "register")}
                variant="body2"
              >
                {t("do_not_have_an_account_sign_up")}
              </Link>
            </Grid>
          ) : null}
        </Grid>
        <Snackbar
          severity="error"
          open={!!usersData.errorMessage}
          message={usersData.errorMessage}
        />
      </Box>
    </Container>
  );
}
