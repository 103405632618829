import { put, takeLatest, call } from "redux-saga/effects";
import Axios from "axios";
import {
  GET_LISTINGS,
  CREATE_LISTING,
  GET_LISTING,
  UPDATE_LISTING,
  UPLOAD_LISTING_MEDIA,
  UPDATE_UNIT_LISTING_REDUCER,
  CREATE_UNIT_LISTING_REDUCER,
  GET_UNIT_LISTINGS,
} from "../constants";
import {
  createListingSuccess,
  updateListingSuccess,
  uploadMediaSuccess,
  getListingsSuccess,
  getListingSuccess,
  updateUnitListingSuccess,
  getUnitListingsSuccess,
} from "./../actions/Listing";
import Fetch from "../../libs/fetch";
import { LISTING_URL, LISTING_DETAIL_URL, MEDIA_URL } from "../api";

export function* getListings(options) {
  try {
    const response = yield call(Fetch, {
      url: LISTING_URL,
      method: "GET",
      headers: null,
      queryParams: options.filter,
      useCache: true,
    });
    yield put(getListingsSuccess(response));
  } catch (error) {
    //yield put(loginError(error));
  }
}

export function* getListing(options) {
  try {
    const response = yield call(Fetch, {
      url: LISTING_DETAIL_URL,
      method: "GET",
      headers: null,
      queryParams: { id: options.id },
    });
    yield put(getListingSuccess(response));
  } catch (error) {
    //yield put(loginError(error));
  }
}

export function* updateListing(options) {
  try {
    const response = yield call(Fetch, {
      url: LISTING_URL,
      method: "PUT",
      headers: null,
      body: JSON.stringify(options.data),
    });
    yield put(updateListingSuccess(response));
  } catch (error) {
    //yield put(loginError(error));
  }
}

export function* createListing(options) {
  const { imageFile: file, ...rest } = options.data;
  try {
    const response = yield call(Fetch, {
      url: LISTING_URL,
      method: "POST",
      headers: null,
      body: JSON.stringify(rest),
    });

    if (file && file.name) {
      const fileNameArr = file.name.split(".");
      const ext = fileNameArr[fileNameArr.length - 1];
      const name = fileNameArr[0] || "";
      const newFileName = `${response._id}/${name.split(" ").join("-")}_${
        response._id
      }.${ext}`;

      let formData = new FormData();
      formData.append("file", file, newFileName);

      const signedRequest = yield call(Fetch, {
        url: MEDIA_URL,
        method: "POST",
        body: JSON.stringify({
          _id: response._id,
          fileType: file["type"],
          fileName: newFileName,
        }),
      });

      yield Axios.put(signedRequest.data.signedRequest, file, {
        "Content-Type": file["type"],
      });

      const responseWithImage = yield call(Fetch, {
        url: LISTING_URL,
        method: "PUT",
        headers: null,
        body: JSON.stringify({
          _id: response._id,
          image: signedRequest.data.url,
        }),
      });
      yield put(createListingSuccess(responseWithImage));
    } else {
      yield put(createListingSuccess(response));
    }
  } catch (error) {
    console.log("error", error);
    //yield put(loginError(error));
  }
}

export function* uploadMedia(options) {
  try {
    console.log(options.data.images, options);
    const mediaList = [];
    const youtubeUrls = [];
    const virtualUrl = [];

    for (let i in options.data.images) {
      let file = options.data.images[i];
      if (file && file.name) {
        const fileNameArr = file.name.split(".");
        const ext = fileNameArr[fileNameArr.length - 1];
        const name = fileNameArr[0] || "";
        const time = new Date().getTime();
        const newFileName = `${options.data.listingId}/${name
          .split(" ")
          .join("-")}_${options.data.listingId}_${time}.${ext}`;

        let formData = new FormData();
        formData.append("file", file, newFileName);

        const response = yield call(Fetch, {
          url: MEDIA_URL,
          method: "POST",
          body: JSON.stringify({
            listingId: options.data.listingId,
            fileType: file["type"],
            fileName: newFileName,
          }),
        });

        yield Axios.put(response.data.signedRequest, file, {
          "Content-Type": file["type"],
        });
        mediaList.push({ url: response.data.url });
      }
    }

    for (let j in options.data.youtubeUrls) {
      if (options.data.youtubeUrls[j]) {
        youtubeUrls.push(options.data.youtubeUrls[j]);
      }
    }

    for (let j in options.data.virtualTours) {
      if (options.data.virtualTours[j]) {
        virtualUrl.push(options.data.virtualTours[j]);
      }
    }

    // Save Youtube
    yield call(Fetch, {
      url: MEDIA_URL,
      method: "PUT",
      body: JSON.stringify({
        listingId: options.data.listingId,
        images: mediaList,
        youtubeUrls,
        virtualUrl,
      }),
    });

    yield put(
      uploadMediaSuccess({
        mediaList,
        youtubeUrls,
        virtualUrl,
      })
    );
  } catch (error) {
    console.log(error, "error");
    //yield put(loginError(error));
  }
}

export default function* propertySaga() {
  //yield all([
  yield takeLatest(GET_LISTINGS, getListings);
  yield takeLatest(GET_LISTING, getListing);
  yield takeLatest(CREATE_LISTING, createListing);
  yield takeLatest(UPDATE_LISTING, updateListing);
  yield takeLatest(UPLOAD_LISTING_MEDIA, uploadMedia);

  //]);
}
