import React from "react";
import { applyMiddleware, compose, createStore } from "redux";
import { Provider } from "react-redux";
import createSagaMiddleware from "redux-saga";
import { reducers } from "./reducers";
import rootSaga from './saga';

const composeEnhancers = typeof window != 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware()
export const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

rootSaga.forEach((saga) => {
  sagaMiddleware.run(saga);
});

export function StoreProvider(props) {
  const { children } = props;
  return <Provider store={store}>{children}</Provider>;
}
