import { GET_POI, GET_POI_SUCCESS, GET_SELECTED_POI_SUCCESS, GET_POI_ERROR } from "../constants";

// Initial State
const initialState = {
  isLoading: false,
  selectedSuggestions: [],
  suggestions: [],
  errorMessage: null,
};

// Reducers (Modifies The State And Returns A New State)
const POIReducer = (state = initialState, action) => {
  switch (action.type) {
    // Login
    case GET_POI:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
      };
    case GET_POI_SUCCESS:
      return {
        ...state,
        isLoading: false,
        suggestions: action.data,
      };
      case GET_SELECTED_POI_SUCCESS:
        return {
          ...state,
          selectedSuggestions: action.data,
        };
    case GET_POI_ERROR:
      return {
        ...state,
        isLoading: false,
        suggestions: [],
        errorMessage: action.message,
      };
    // Default
    default:
      return state;
  }
};

// Exports
export default POIReducer;
