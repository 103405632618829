const PATH = "/api";
const isProd = process.env.NODE_ENV === "production";
export const HOST = isProd
  ? `http://findsportspartner.com`
  : "http://localhost:3000";
export const LISTING_URL = `${PATH}/listings`;
export const LISTING_DETAIL_URL = `${PATH}/listing`;
export const LOGIN_URL = `${PATH}/users/login`;
export const REGISTER_URL = `${PATH}/users/register`;
export const USER_PROFILE_URL = `${PATH}/users/me`;
export const USER_DETAIL = `${PATH}/users`;
export const MEDIA_URL = `${PATH}/media`;
export const POI_PAGE_URL = `${PATH}/poi`;
export const ALL_USERS = `${PATH}/users/all`;
