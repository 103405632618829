import { put, takeEvery, takeLatest, call, all } from "redux-saga/effects";
import Axios from 'axios';
import {
  LOGIN,
  REGISTER,
  USER_PROFILE,
  UPDATE_USER_PROFILE,
  UPLOAD_USER_PROFILE_PIC,
} from "../constants";
import {
  loginSuccess,
  loginError,
  registerError,
  registerSuccess,
  getUserProfileSuccess,
  saveUserProfileSuccess,
  saveUserProfileError,
  getUserProfile as getUserProfileAction,
} from "./../actions/Users";
import Fetch from "../../libs/fetch";
import { LOGIN_URL, REGISTER_URL, USER_PROFILE_URL, USER_DETAIL, MEDIA_URL } from "../api";

export function* doLogin(options) {
  try {
    const response = yield call(Fetch, {
      url: LOGIN_URL,
      method: "POST",
      headers: null,
      body: JSON.stringify(options.data),
    });
    yield put(loginSuccess(response));
    yield put(getUserProfileAction({}));
  } catch (error) {
    yield put(loginError(error));
  }
}

export function* updateUserProfile(options) {
  try {
    const response = yield call(Fetch, {
      url: USER_DETAIL,
      method: "PUT",
      headers: null,
      body: JSON.stringify(options.data),
    });

    if (response.statusCode >= 400) {
      throw response.message;
    }
    yield put(saveUserProfileSuccess(response));
  } catch (error) {
    yield put(saveUserProfileError(error));
  }
}

export function* getUserProfile(options) {
  try {
    const response = yield call(Fetch, {
      url: USER_PROFILE_URL,
      method: "GET",
      headers: null,
      queryParams: options.data || {},
    });
    yield put(getUserProfileSuccess(response));
  } catch (error) {
    //yield put(loginError(error));
  }
}

export function* doRegister(options) {
  try {
    const response = yield call(Fetch, {
      url: REGISTER_URL,
      method: "POST",
      headers: null,
      body: JSON.stringify(options.data),
    });

    if (response.status > 300) {
      throw response.message;
    } else {
      yield put(registerSuccess(response));
      yield put(getUserProfileAction({}));
    }
  } catch (error) {
    yield put(registerError(error));
  }
}

export function* uploadImages(options) {
  try {
    const mediaList = [];

    for (let i in options.data.images) {
      let file = options.data.images[i];
      if (file && file.name) {
        const fileNameArr = file.name.split(".");
        const ext = fileNameArr[fileNameArr.length - 1];
        const name = fileNameArr[0] || "";
        const time = new Date().getTime();
        const newFileName = `${options.data.userId}/${name
          .split(" ")
          .join("-")}_${options.data.userId}_${time}.${ext}`;

        let formData = new FormData();
        formData.append("file", file, newFileName);

        const response = yield call(Fetch, {
          url: MEDIA_URL,
          method: "POST",
          body: JSON.stringify({
            listingId: options.data.listingId,
            fileType: file["type"],
            fileName: newFileName,
          }),
        });

        yield Axios.put(response.data.signedRequest, file, {
          "Content-Type": file["type"],
        });
        mediaList.push({ url: response.data.url });
      }
    }

    const dataToUpdate = {
      media: mediaList,
      _id: options.data.userId,
    };

    const response = yield call(Fetch, {
      url: USER_DETAIL,
      method: "PUT",
      headers: null,
      body: JSON.stringify(dataToUpdate),
    });

    if (response.statusCode >= 400) {
      throw response.message;
    }

    yield put(saveUserProfileSuccess(dataToUpdate));
  } catch (error) {
    console.log(error, "error");
    yield put(saveUserProfileError(error));
  }
}

export default function* propertySaga() {
  yield takeEvery(LOGIN, doLogin);
  yield takeEvery(REGISTER, doRegister);
  yield takeLatest(USER_PROFILE, getUserProfile);
  yield takeEvery(UPDATE_USER_PROFILE, updateUserProfile);
  yield takeEvery(UPLOAD_USER_PROFILE_PIC, uploadImages);
}
