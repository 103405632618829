import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  field: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  dropdownField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    minWidth: 120,
    width: "100%",
  },
  dateField: {
    display: "flex",
    flexDirection: "column",
  },
  dateFieldButton: {
    marginRight: theme.spacing(1),
  },
  root: {
    display: "flex",
    flex: 1,
    position: "relative",
  },
  label: {
    display: "block",
  },
  listbox: {
    width: 500,
    marginTop: 50,
    padding: 0,
    zIndex: 1,
    position: "absolute",
    listStyle: "none",
    backgroundColor: theme.palette.background.paper,
    overflow: "auto",
    maxHeight: 200,
    border: "1px solid rgba(0,0,0,.25)",
    "& li": {
      textAlign: "left",
      padding: 10,
    },
    '& li[data-focus="true"]': {
      backgroundColor: theme.palette.secondary.main,
      color: "white",
      cursor: "pointer",
    },
    "& li:active": {
      backgroundColor: theme.palette.secondary.main,
      color: "white",
    },
  },
}));

export default useStyles;
