import React, { Fragment } from "react";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Grid from "@material-ui/core/Grid";
import useTranslation from "next-translate/useTranslation";

function PopupComponent({ title, handleClose, open, children, submitHandler, submitButtonLabel, fullScreen = false, maxWidth = false, className, fullWidth = false }) {
  const { t } = useTranslation("common");
  return (
      <Dialog maxWidth={maxWidth} open={open} className={className} fullWidth={fullWidth} fullScreen={fullScreen}>
        <DialogTitle onClose={handleClose}>
          <Grid container justify="space-between" alignItems="center">
          {title}
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            </Grid>
        </DialogTitle>
        <DialogContent dividers>{children}</DialogContent>
        {submitHandler ? <DialogActions>
          <Button autoFocus onClick={handleClose} color="default">
            {t("cancel")}
          </Button>
          <Button autoFocus onClick={submitHandler} color="primary">
            {submitButtonLabel}
          </Button>
        </DialogActions>
      : null}
      </Dialog>
  );
}

export default PopupComponent;
