import cookieCutter from "cookie-cutter";
import { resolveHref } from "next/dist/shared/lib/router/router";
import LruCache from "./lru";
// Get a cookie

const defaultHeader = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export default function Fetch({
  url,
  method,
  headers,
  body,
  queryParams,
  noAuth = false,
  useCache = false,
}) {
  const options = {
    method: method || "GET",
    headers: headers || defaultHeader,
  };

  if (
    !noAuth &&
    cookieCutter &&
    cookieCutter.get &&
    cookieCutter.get("token")
  ) {
    options.headers.Authorization = "Bearer " + cookieCutter.get("token");
  }

  if (queryParams) {
    for (let i in queryParams) {
      if (url.includes("?")) {
        url += `&`;
      } else {
        url += `?`;
      }

      url += `${i}=${queryParams[i]}`;
    }
  }

  if (useCache && LruCache.get(url)) {
    return Promise.resolve(JSON.parse(LruCache.get(url))).then((data) => data);
  }

  if (body) {
    options.body = body;
  }

  return fetch(url, options)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      if (useCache) {
        LruCache.set(url, JSON.stringify(data));
      }
      return data;
    })
    .catch((error) => {
      return error;
    });
}
