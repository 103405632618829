import React, { Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import NextLink from "../NextLink";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import useTranslation from "next-translate/useTranslation";
import FavoriteIcon from "@material-ui/icons/Favorite";

const useStyles = makeStyles((theme) => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none",
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: "wrap",
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2),
  },
  footerWrapper: {
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(4),
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    color: "#FFF",
    backgroundColor: "#515b6d",
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  anchorStyle: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

export default function FooterComponent() {
  const classes = useStyles();
  const { t } = useTranslation("common");

  return (
    <Container
      maxWidth={false}
      component="footer"
      className={classes.footerWrapper}
    >
      <Box mt={3} mb={3}>
        <Grid container xs={12}>
          <Grid item xs={12}>
            <Grid container xs={12} spacing={2} justifyContent="center">
              <Grid item>
                <NextLink className={classes.anchorStyle} href="/cookie-policy">
                  {t("cookie_policy")}
                </NextLink>
              </Grid>
              <Grid item>
                <NextLink className={classes.anchorStyle} href="/terms-of-use">
                  {t("terms_of_use")}
                </NextLink>
              </Grid>
              <Grid item>
                <NextLink
                  className={classes.anchorStyle}
                  href="/privacy-policy"
                >
                  {t("privacy_policy")}
                </NextLink>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2" color="textSecondary" align="center">
              {"Copyright © "}
              <Link color="inherit" href="/">
                FindSportsPartner
              </Link>{" "}
              {new Date().getFullYear()}
              {"."}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              variant="body2"
              color="inherit"
              align="center"
            >
              Made with <FavoriteIcon color="primary" /> &amp; some logic
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              variant="body2"
              color="inherit"
              align="center"
            >
              Contact Us&nbsp;{" "}
              <a href="mailto:contact@findsportspartner.com">
                contact@findsportspartner.com
              </a>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
