const isProd = process.env.NODE_ENV === "production";
export const HOST = isProd
  ? `https://findsportspartner.com`
  : "http://localhost:3000";
export const FORGOT_PASSWORD = "/forgot-password";
export const SIGN_UP = "/register";
export const SIGN_IN = "/login";
export const HOME_ROUTE = "/";
export const SEARCH_PAGE_ROUTE = "/search";
export const getPostPropertyForFreeUrl = (city) => `/${city}/post-ads-for-free`;

export const CONTACT_US = "/contact-us";
export const ABOUT_US = "/about-us";
export const PRIVACY_POLICY = "/privacy-policy";
export const TERMS_OF_USE = "/terms-of-use";

// Agent Routes
export const USER_PATH = "/user";
export const AGENT_DASHBOARD = `${USER_PATH}/dashboard`;
export const CREATE_LISTING = `${USER_PATH}/create-listing`;
export const LISTING = `${USER_PATH}/listings`;
export const EDIT_PROFILE = `${USER_PATH}/profile`;

// Social Media

export const FACEBOOK = "https://www.facebook.com/findsportspartner/";
export const TWITTER = "https://twitter.com";
export const INSTAGRAM = "https://instagram.com";
export const PINTEREST = "https://pintrest.com";

export const getUserProfileView = (username) => `${USER_PATH}/${username}`;
export const UNAUTH_ROUTES = [TERMS_OF_USE, ABOUT_US, CONTACT_US, PRIVACY_POLICY];
