import { combineReducers } from "redux";

import UsersReducer from "./Users";
import ListingReducer from "./Listing";
import POIReducer from "./POI";

export const reducers = combineReducers({
  UsersReducer,
  ListingReducer,
  POIReducer,
});
