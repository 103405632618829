import { Fragment } from "react";
import Modal from "@material-ui/core/Modal";
import screenSize from "./../../states/screen";
import Popup from "./Popup";

const PopupComponent = ({ handleClose, open, ...props }) => {
  const isDesktop = screenSize();

  return (
    <Fragment>
      {isDesktop ? (
        <Modal disableEscapeKeyDown={true} onClose={handleClose} open={open}>
          <Popup
            handleClose={handleClose}
            open={open}
            fullWidth={true}
            maxWidth="md"
            {...props}
          />
        </Modal>
      ) : (
        <Popup
          handleClose={handleClose}
          open={open}
          {...props}
          fullScreen={true}
        />
      )}
    </Fragment>
  );
};

export default PopupComponent;
