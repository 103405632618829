/* eslint-disable no-use-before-define */
import React, { useCallback } from "react";
import useTranslation from "next-translate/useTranslation";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import NoSsr from "@material-ui/core/NoSsr";
import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
import _ from "lodash";

export default function UseAutocomplete({
  suggestions,
  setValue,
  fetchSuggestions,
  isLoading,
  value,
  disabled,
  selectedSuggestions = [],
}) {
  const debounce = useCallback(
    _.debounce((_searchVal) => {
      fetchSuggestions(_searchVal);
    }, 300),
    []
  );

  const { t } = useTranslation("common");

  return (
    <Autocomplete
      style={{ borderRadius: "5px" }}
      fullWidth
      multiple
      limitTags={2}
      value={value}
      options={suggestions}
      disabled={disabled}
      // defaultValue={selectedSuggestions}
      getOptionSelected={(option, option2) => {
        return option._id === option2._id;
      }}
      getOptionLabel={(option) => option.name}
      onChange={(event, value) => {
        setValue(value);
      }}
      loading={isLoading}
      groupBy={(option) => option.type}
      onInputChange={(_, value) => {
        debounce(value);
      }}
      renderInput={(params) => {
        params.inputProps.autoCapitalize = undefined;
        return (
          <TextField
            {...params}
            size="small"
            fullWidth
            style={{ backgroundColor: "#FFF", borderRadius: "5px" }}
            variant="outlined"
            placeholder={t("autocomplete_placeholder")}
          />
        );
      }}
    />
  );
}
