import {
  LOGIN,
  REGISTER,
  LOGIN_SUCCESS,
  REGISTER_SUCCESS,
  USER_PROFILE,
  USER_PROFILE_SUCCESS,
  REGISTER_ERROR,
  UPDATE_USER_PROFILE,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_ERROR,
  LOGOUT,
  UPDATE_USER_STATE,
  UPLOAD_USER_PROFILE_PIC,
} from "./../constants";

export const doLogin = (data) => ({
  type: LOGIN,
  data,
});

export const doLogout = () => ({
  type: LOGOUT,
});

export const getUserProfile = (data) => ({
  type: USER_PROFILE,
  data,
});

export const doRegister = (data) => ({
  type: REGISTER,
  data,
});

export const loginSuccess = (data) => ({
  type: LOGIN_SUCCESS,
  data,
});

export const loginError = (data) => ({
  type: LOGIN_ERROR,
  data,
});
export const registerError = (data) => ({
  type: REGISTER_ERROR,
  data,
});

export const getUserProfileSuccess = (data) => ({
  type: USER_PROFILE_SUCCESS,
  data,
});

export const registerSuccess = (data) => ({
  type: REGISTER_SUCCESS,
  data,
});

export const saveUserProfile = (data) => ({
  type: UPDATE_USER_PROFILE,
  data,
});

export const saveUserProfileSuccess = (data) => ({
  type: UPDATE_USER_PROFILE_SUCCESS,
  data,
});

export const updateUserState = (data) => ({
  type: UPDATE_USER_STATE,
  data,
});

export const saveUserProfileError = () => ({
  type: UPDATE_USER_PROFILE_ERROR,
});

export const uploadImageActions = (data) => ({
  type: UPLOAD_USER_PROFILE_PIC,
  data,
});
