import React, { Fragment } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { connect } from "react-redux";
import dynamic from "next/dynamic";
import useTranslation from "next-translate/useTranslation";
import { Formik, Form, useField } from "formik";
import NextLink from "./../NextLink";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FORGOT_PASSWORD, SIGN_IN } from "./../../config/routes";
import useStyles from "./style";
import InputBox from "./../FormikInput/Input";
import CheckBox from "./../FormikInput/Checkbox";

const SocialLogin = dynamic(() => import("../SocialLogin"), {
  ssr: false,
});

function SignUp({ handleSubmit, isLoading }) {
  const classes = useStyles();
  const { t } = useTranslation("common");

  return (
    <Fragment>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        {t("register_to_website")}
      </Typography>
      <br />
      <Grid container xs={12}>
        <SocialLogin signup={true} isLoading={isLoading} />
        <Divider light={false} className={classes.divider} />
      </Grid>
    </Fragment>
  );
}

export default SignUp;
