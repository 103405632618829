import React from "react";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { useDispatch } from "react-redux";
import useTranslation from "next-translate/useTranslation";
import cookieCutter from "cookie-cutter";
import { doLogout } from "../../../store/actions/Users";
import { EDIT_PROFILE, VIEW_PROFILE } from "./../../config/routes";
import { useRouter } from "next/router";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));

export default function UserActionMenuList({ user }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const router = useRouter();
  const { t } = useTranslation("common");
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  function navigate(event, pathName) {
    router.push({
      pathname: pathName,
    });
    handleClose(event);
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function logout(event) {
    cookieCutter.set("token", cookieCutter.get("token"), {
      expires: new Date(0),
    });
    dispatch(doLogout());
    handleClose(event);
  }

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const picture =
    user?.media && user.media?.length ? user.media[0]?.url : user.picture;

  return (
    <div className={classes.root}>
      <div>
        <Button
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <Grid container justify="space-between" alignItems="center">
            <Avatar
              alt={user.name}
              src={picture}
              imgProps={{
                style: {
                  height: "auto",
                },
              }}
            />
          </Grid>
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          style={{ zIndex: 1101 }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem
                      onClick={(event) => navigate(event, EDIT_PROFILE)}
                    >
                      {t("edit_account")}
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={logout}>{t("logout")}</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}
