import { Fragment } from "react";
import { Button, Box } from "@material-ui/core";
import Autocomplete from "./../Autocomplete";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  searchIcon: {
    fontSize: 24,
    color: "#FFF",
  },
  searchBar: {
    margin: 0,
    display: "flex",
  },
  inputStyles: {
    width: "100%",
    padding: "15px",
    fontSize: 18,
  },
  inputStylesHeader: {
    width: "100%",
    padding: "5px",
    fontSize: 18,
  },
});

const SearchBarComponent = ({
  suggestions,
  value,
  setValue,
  fetchSuggestions,
  isHeader,
  isLoading,
  selectedSuggestions,
  disabled,
}) => {
  const classes = useStyles();
  return (
    <Box m={1} className={classes.searchBar}>
      <Autocomplete
        selectedSuggestions={selectedSuggestions}
        inputStyles={isHeader ? classes.inputStylesHeader : classes.inputStyles}
        suggestions={suggestions}
        setValue={setValue}
        value={value}
        fetchSuggestions={fetchSuggestions}
        isLoading={isLoading}
        disabled={disabled}
      />
    </Box>
  );
};

export default SearchBarComponent;
