import { makeStyles } from "@material-ui/core/styles";

const globalStyles = makeStyles((theme) => ({
  "@global": {
    ".MuiChip-root": {
      borderRadius: "5px",
    },
    ".image-gallery": {
      width: "100%",
      height: "auto",
    },
    ".fullscreen": {
      height: "85%",
      display: "flex",
      flexDirection: "column",
    },
    ".image-gallery-slide-wrapper": {
      flex: 1,
    },
    ".fullscreen .image-gallery-slide div": {
      width: "100%",
      height: "600px",
      [theme.breakpoints.down("sm")]: {
        height: "400px",
      },
    },
    ".fullscreen .image-gallery-slide img": {
      width: "auto",
      height: "auto",
      maxHeight: "100%",
      maxWidth: "100%",
      objectFit: "cover",
      overflow: "hidden",
      objectPosition: "center center",
    },
    "button.image-gallery-icon:not(.image-gallery-fullscreen-button) .image-gallery-svg":
      {
        [theme.breakpoints.up("sm")]: {
          height: "80px",
        },
      },
    "button.image-gallery-fullscreen-button .image-gallery-svg": {
      [theme.breakpoints.up("sm")]: {
        height: "24px",
        width: "24px",
      },
    },
    ".MuiBreadcrumbs-ol": {
      flexWrap: "nowrap",
    },
    ".image-gallery-thumbnails-wrapper": {
      display: "none",
    },
    ".fullscreen .image-gallery-thumbnails-wrapper": {
      display: "block",
    },
    ".image-gallery.fullscreen-modal": {
      zIndex: 1100,
    },
    ".leaflet-marker-icon": {
      textAlign: "center",
    },
    ".alice-carousel__stage-item": {
      height: "280px",
      background: "#F0F0F0",
    },
    ".alice-carousel__next-btn": {
      position: "absolute",
      width: "23px",
      right: "10px",
      top: "44%",
    },
    ".alice-carousel__prev-btn": {
      position: "absolute",
      width: "23px",
      left: "-15px",
      top: "44%",
    },
    ".alice-carousel__next-btn span": {
      width: "25px",
      height: "50px",
      fontSize: "28px",
      color: "#919ba0",
      padding: "10px",
      background: "#FFF",
      borderRadius: "50px 0 0 50px",
    },
    ".alice-carousel__prev-btn span": {
      width: "25px",
      height: "50px",
      
      fontSize: "28px",
      color: "#919ba0",
      background: "#FFF",
      padding: "10px",
      borderRadius: "0 50px 50px 0",
    },
    ".desktop-show": {
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
    },
    ".mobile-show": {
      display: "none",
      [theme.breakpoints.down("xs")]: {
        display: "contents",
      },
    },
  },
}));

export default globalStyles;
