import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { useField } from "formik";
import useStyles from "./style";

const renderCheckbox = ({ label, ...props }) => {
  const classes = useStyles();
  const [field, meta] = useField(props);
  return (
    <FormControlLabel
      className={classes.field}
      control={
        <Checkbox label={label} checked={field.value} {...field} {...props} />
      }
      label={label}
    />
  );
};

export default renderCheckbox;
