export const CREATE_LISTING = "CREATE_LISTING";
export const UPDATE_LISTING = "UPDATE_LISTING";
export const CREATE_LISTING_SUCCESS = "CREATE_LISTING_SUCCESS";
export const UPDATE_LISTING_SUCCESS = "UPDATE_LISTING_SUCCESS";
export const GET_LISTINGS = "GET_LISTINGS";
export const GET_LISTING = "GET_LISTING";
export const GET_LISTING_SUCCESS = "GET_LISTING_SUCCESS";
export const GET_LISTINGS_SUCCESS = "GET_LISTINGS_SUCCESS";
export const MAKE_FAVOURITE = "MAKE_FAVOURITE";
export const REMOVE_FAVOURITE = "REMOVE_FAVOURITE";
export const USER_PROFILE = "USER_PROFILE";
export const UPDATE_UNIT_LISTING_REDUCER = "UPDATE_UNIT_LISTING_REDUCER";
export const CREATE_UNIT_LISTING_REDUCER = "CREATE_UNIT_LISTING_REDUCER";
export const CREATE_UNIT_LISTING_SUCCESS = "CREATE_UNIT_LISTING_SUCCESS";
export const UPDATE_UNIT_LISTING_SUCCESS = "UPDATE_UNIT_LISTING_SUCCESS";
export const GET_UNIT_LISTINGS = "GET_UNIT_LISTINGS";
export const GET_UNIT_LISTINGS_SUCCESS = "GET_UNIT_LISTINGS_SUCCESS";
export const UPLOAD_LISTING_DATA_MEDIA = "UPLOAD_LISTING_DATA_MEDIA";

export const LOGIN = "LOGIN";
export const REGISTER = "REGISTER";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const USER_PROFILE_SUCCESS = "USER_PROFILE_SUCCESS";
export const REGISTER_ERROR = "REGISTER_ERROR";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const UPDATE_LISTING_REDUCER = "UPDATE_LISTING_REDUCER";
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS";
export const LOGOUT = "LOGOUT";

export const UPLOAD_LISTING_MEDIA = "UPLOAD_LISTING_MEDIA";
export const UPLOAD_LISTING_MEDIA_SUCCESS = "UPLOAD_LISTING_MEDIA_SUCCESS";

export const GET_POI = "GET_POI";
export const GET_POI_SUCCESS = "GET_POI_SUCCESS";
export const GET_POI_ERROR = "GET_POI_ERROR";
export const GET_SELECTED_POI = "GET_SELECTED_POI";
export const GET_SELECTED_POI_SUCCESS = "GET_SELECTED_POI_SUCCESS";
export const UPDATE_USER_PROFILE_ERROR = "UPDATE_USER_PROFILE_ERROR";
export const UPDATE_USER_STATE = "UPDATE_USER_STATE";
export const UPLOAD_USER_PROFILE_PIC = "UPLOAD_USER_PROFILE_PIC";
