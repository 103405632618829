import LRU from "lru-cache";

const options = {
  max: 500,
  length: function (n, key) {
    return n * 2 + key.length;
  },
  maxAge: 1000 * 60 * 5,
};
const cache = new LRU(options);

export default cache;
