import { GET_POI, GET_POI_SUCCESS, GET_SELECTED_POI_SUCCESS, GET_POI_ERROR, GET_SELECTED_POI } from "../constants";

export const getPOI = (options) => ({
  type: GET_POI,
  options,
});

export const getPOISuccess = (data) => ({
  type: GET_POI_SUCCESS,
  data,
});


export const getSelectedPOISuccess = (data) => ({
  type: GET_SELECTED_POI_SUCCESS,
  data,
});



export const getPOIError = (message) => ({
  type: GET_POI_ERROR,
  message,
});

export const getSelectedPOI = (ids) => ({
  type: GET_SELECTED_POI,
  ids,
});