import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import NextLink from "../NextLink";
import Link from "@material-ui/core/Link";
import useTranslation from "next-translate/useTranslation";
import {
  SIGN_IN,
  SIGN_UP,
  CREATE_LISTING,
  FOR_RENT_PAGE_ROUTE,
  FOR_BUY_PAGE_ROUTE,
  CONDO_FOR_BUY_PAGE_ROUTE,
  CONDO_FOR_RENT_PAGE_ROUTE,
  HDB_FOR_BUY_PAGE_ROUTE,
  HDB_FOR_RENT_PAGE_ROUTE,
  ROOM_FOR_RENT_PAGE_ROUTE,
  CONDO_ROUTE,
} from "../../config/routes";
import useStyles from "./style";
import { useRouter } from "next/router";
import NavMenu from "./../NavMenu";
import SearchBar from "./../SearchBar";
import MenuDrawer from "./../MenuDrawer";
import Container from "@material-ui/core/Container";
import { useSelector, useDispatch } from "react-redux";
import useUsersStatus from "./../../states/users";
import screenSize from "./../../states/screen";
import UserProfile from "./UserProfile";
import Language from "./Language";
import Menu from "./../Menu";
import PopUp from "./../Popup";
import AuthModal from "../Auth/AuthModal";
import { getUserProfile } from "../../../store/actions/Users";


export default function Header({ city }) {
  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(false);
  const classes = useStyles();
  const router = useRouter();
  const isLoggesIn = useUsersStatus();
  const isDesktop = screenSize();
  const { t } = useTranslation("common");

  const usersData = useSelector((state) => {
    return state.UsersReducer;
  });

  useEffect(() => {
    if (isLoggesIn && !usersData.isLoading && !usersData.userDetails.email) {
      dispatch(getUserProfile());
    }
  }, [isLoggesIn]);

  return (
    <Container
      maxWidth={false}
      component="header"
      disableGutters={true}
      className={classes.toolbar}
    >
      <Container maxWidth="md" disableGutters={true}>
        <Toolbar
          disableGutters={true}
          style={{ alignItems: "center", justifyContent: "space-between" }}
        >
          {!isDesktop ? (
            <MenuDrawer user={usersData.userDetails} showImage={true} />
          ) : null}
          <Typography
            component="h2"
            variant="h5"
            color="inherit"
            className={classes.toolbarTitle}
          >
            <NextLink href="/" legacyBehavior>
              <a className={classes.logo}>Find a Sport Partner</a>
            </NextLink>
          </Typography>
          <Toolbar
            component="div"
            disableGutters={!isDesktop}
            className={classes.menuBar}
          >
            <Language />
            <Hidden only={["xs", "sm"]}>
              {!isLoggesIn ? null : (
                <UserProfile user={usersData.userDetails} />
              )}
            </Hidden>
            {!isLoggesIn ? (
              <Hidden only={["xs", "sm"]}>
                &nbsp;&nbsp;
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => setShowPopup(true)}
                >
                  {t("sign_up")}
                </Button>
                &nbsp;&nbsp;
                <Button
                  color="default"
                  variant="outlined"
                  onClick={() => setShowPopup(true)}
                >
                  {t("log_in")}
                </Button>
              </Hidden>
            ) : null}
            {isDesktop ? <MenuDrawer user={usersData.userDetails} /> : null}
          </Toolbar>
        </Toolbar>
      </Container>
      {!isLoggesIn && showPopup ? (
        <PopUp
          title="Login"
          open={showPopup}
          handleClose={() => {
            setShowPopup(false);
          }}
        >
          <AuthModal />
        </PopUp>
      ) : null}
    </Container>
  );
}

Header.propTypes = {
  sections: PropTypes.array,
  title: PropTypes.string,
};
