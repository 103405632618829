import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  stickyHeader: {
    position: "fixed",
    height: "65px",
    top: 0,
    zIndex: theme.zIndex.appBar,
    backgroundColor: theme.palette.background.default,
  },
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.up("md")]: {
      position: "fixed",
      zIndex: theme.zIndex.appBar,
      backgroundColor: theme.palette.background.default,
      top: 0,
    },
  },
  toolbarTitle: {
    flex: 1,
    lineHeight: "18px",
    [theme.breakpoints.down("xs")]: {
      flex: 3,
      textAlign: "center",
    },
  },
  toolbarSecondary: {
    justifyContent: "flex-start",
    overflowX: "auto",
    flex: 3,
  },
  navLink: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    flexShrink: 0,
    textDecoration: "none",
    color: "#000",
    fontSize: 16,
    borderBottom: "2px solid " + theme.palette.common.white,
    "&:hover": {
      borderBottom: "2px solid " + theme.palette.secondary.main,
    },
  },
  menuBar: {
    flex: 2,
    justifyContent: "flex-end",
    [theme.breakpoints.down("xs")]: {
      flex: 1,
    },
  },
  signUpButton: {
    marginRight: theme.spacing(2),
  },
  logo: {
    background: "url(/logo.png)",
    backgroundRepeat: "no-repeat",
    width: 192,
    height: 62,
    display: "inline-block",
    backgroundPosition: "right",
    textIndent: -9999,
  },
}));

export default useStyles;
