import React, { Fragment, useState } from "react";
import MenuIcon from "@material-ui/icons/Menu";
import Drawer from "@material-ui/core/Drawer";
import { useRouter } from "next/router";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import Collapse from "@material-ui/core/Collapse";
import MailIcon from "@material-ui/icons/Mail";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import StarBorder from "@material-ui/icons/StarBorder";
import useTranslation from "next-translate/useTranslation";
import cookieCutter from "cookie-cutter";
import PostAddTwoToneIcon from "@material-ui/icons/PostAddTwoTone";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import LockOpenTwoToneIcon from "@material-ui/icons/LockOpenTwoTone";
import DashboardTwoToneIcon from "@material-ui/icons/DashboardTwoTone";
import LockTwoToneIcon from "@material-ui/icons/LockTwoTone";
import { useDispatch } from "react-redux";
import VpnKeyTwoToneIcon from "@material-ui/icons/VpnKeyTwoTone";
import useUsersStatus from "./../../states/users";
import {
  SIGN_IN,
  SIGN_UP,
  CREATE_LISTING,
  HOME_ROUTE,
  CONDO_ROUTE,
} from "../../config/routes";
import { mainListItems, secondaryListItems } from "./listItems";
import { doLogout } from "../../../store/actions/Users";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
});

export default function MenuDrawer({ user, showImage }) {
  const classes = useStyles();
  const router = useRouter();
  const theme = useTheme();
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [openDrawer, setOpenDrawer] = useState(false);
  const isLoggesIn = useUsersStatus();
  const [openBuyMenu, setOpenBuyMenu] = useState(true);
  const [openRentMenu, setOpenRentMenu] = useState(true);
  const { t } = useTranslation("common");

  const moveTo = (routePath) => {
    router.push(routePath);
  };

  const singUpLinks = [];
  if (!isLoggesIn) {
    singUpLinks.push({
      label: t("log_in"),
      route: SIGN_IN,
      Icon: <LockOpenTwoToneIcon />,
    });
    singUpLinks.push({
      label: t("sing_up"),
      route: SIGN_UP,
      Icon: <VpnKeyTwoToneIcon />,
    });
  }

  const handleDrawerClose = () => {
    setOpenBuyMenu(false);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenDrawer(open);
  };

  const picture =
    user?.media && user.media?.length ? user.media[0]?.url : user.picture;

  const list = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {isLoggesIn ? (
          <ListItem>
            <ListItemAvatar>
              {/* <Typography variant="body">{user.name} &nbsp;</Typography> */}
              <Avatar
                alt={user.name}
                src={picture}
                imgProps={{
                  style: {
                    height: "auto",
                  },
                }}
              />
            </ListItemAvatar>
            <ListItemText primary={user.name} />
          </ListItem>
        ) : null}
        {singUpLinks.map(({ label, route, Icon }, index) => (
          <ListItem
            button
            key={label}
            onClick={() => {
              moveTo(route);
            }}
          >
            <ListItemIcon>{Icon}</ListItemIcon>
            <ListItemText primary={label} />
          </ListItem>
        ))}
      </List>
      {isLoggesIn ? (
        <Fragment>
          {/* <Divider />
          {mainListItems({ handleDrawerClose, isDesktop })} */}
          <Divider />
          {secondaryListItems()}
        </Fragment>
      ) : null}
      <Divider />
      <List>
        {isLoggesIn ? (
          <ListItem
            button
            onClick={() => {
              cookieCutter.set("token", cookieCutter.get("token"), {
                expires: new Date(0),
              });
              dispatch(doLogout());
              moveTo(HOME_ROUTE);
            }}
          >
            <ListItemIcon>
              <LockTwoToneIcon />
            </ListItemIcon>
            <ListItemText primary={t("logout")} />
          </ListItem>
        ) : null}
      </List>
    </div>
  );

  return (
    <Fragment>
      <Button onClick={toggleDrawer(true)}>
        <MenuIcon />
      </Button>
      <Drawer
        anchor={isDesktop ? "right" : "left"}
        open={openDrawer}
        onClose={toggleDrawer(false)}
      >
        {list()}
      </Drawer>
    </Fragment>
  );
}
