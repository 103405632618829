import { put, takeLatest, call, all } from "redux-saga/effects";
import { GET_POI, GET_SELECTED_POI } from "../constants";
import {
  getPOISuccess,
  getPOIError,
  getSelectedPOISuccess,
} from "./../actions/POI";
import Fetch from "../../libs/fetch";
import { POI_PAGE_URL, PROPERTY_URL } from "../api";

export function* getPois({ options }) {
  try {
    const [poiResponse, propertyResponse] = yield all([
      call(Fetch, {
        url: POI_PAGE_URL,
        method: "GET",
        headers: null,
        queryParams: options,
        useCache: true,
      }),
      call(Fetch, {
        url: PROPERTY_URL,
        method: "GET",
        headers: null,
        queryParams: { searchString: options.name },
        useCache: true,
      }),
    ]);

    console.log([...poiResponse, ...propertyResponse.properties]);

    yield put(getPOISuccess([...poiResponse, ...propertyResponse.properties]));
  } catch (error) {
    yield put(getPOIError(error));
  }
}

export function* getSelectedPOIs({ ids }) {
  try {
    let response = yield call(Fetch, {
      url: POI_PAGE_URL,
      method: "GET",
      headers: null,
      queryParams: { ids },
      useCache: true,
    });

    yield put(getSelectedPOISuccess(response));
  } catch (error) {
    yield put(getPOIError(error));
  }
}

export default function* POISaga() {
  yield takeLatest(GET_POI, getPois);
  yield takeLatest(GET_SELECTED_POI, getSelectedPOIs);
}
