import {
  CREATE_LISTING_SUCCESS,
  UPDATE_LISTING,
  CREATE_LISTING,
  UPDATE_LISTING_SUCCESS,
  GET_LISTINGS,
  UPDATE_LISTING_REDUCER,
  UPLOAD_LISTING_MEDIA,
  UPLOAD_LISTING_MEDIA_SUCCESS,
  GET_LISTINGS_SUCCESS,
  GET_LISTING_SUCCESS,
  GET_LISTING,
  MAKE_FAVOURITE,
  REMOVE_FAVOURITE,
  UPLOAD_LISTING_DATA_MEDIA,
} from "../constants";

// Initial State
const initialState = {
  isLoading: false,
  createdSucess: false,
  listingData: {},
  listings: [],
  totalRecords: 0,
  favourites: [],
};

// Reducers (Modifies The State And Returns A New State)
const ListingReducer = (state = initialState, action) => {
  switch (action.type) {
    // Login
    case CREATE_LISTING:
      return {
        ...state,
        isLoading: true,
        createdSucess: false,
      };
    case MAKE_FAVOURITE:
      return {
        ...state,
        favourites: [...state.favourites, action.id],
      };
    case REMOVE_FAVOURITE:
      return {
        ...state,
        favourites: state.favourites.filter(
          (favourite) => favourite !== action.id
        ),
      };
    case UPDATE_LISTING:
      return {
        ...state,
        isLoading: true,
        createdSucess: false,
      };
    case CREATE_LISTING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listingData: action.data,
        createdSucess: true,
      };
    case UPDATE_LISTING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        createdSucess: true,
        listingData: {
          ...state.listingData,
          ...action.data.listingData,
        },
      };
    case UPLOAD_LISTING_MEDIA:
      return {
        ...state,
        isLoading: true,
        createdSucess: false,
      };
    case UPLOAD_LISTING_DATA_MEDIA:
      return {
        ...state,
        listingData: {
          ...state.listingData,
          ...action.data,
        },
      };
    case GET_LISTINGS:
      return {
        ...state,
        isLoading: true,
      };

    case GET_LISTING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listingData: action.data,
      };
    case GET_LISTING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_LISTINGS_SUCCESS:
      return {
        ...state,
        totalRecords: action.data.totalRecords || 0,
        listings: action.data.listings || [],
        isLoading: false,
      };
    // Default
    default:
      return state;
  }
};

// Exports
export default ListingReducer;
