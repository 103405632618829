import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import cookieCutter from "cookie-cutter";
import { useRouter } from "next/router";
import { EDIT_PROFILE, UNAUTH_ROUTES } from "./../config/routes";
import { getUserProfile } from "../../store/actions/Users";

export default () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const [token, setToken] = useState(
    cookieCutter && cookieCutter.get ? cookieCutter.get("token") : null
  );
  const [isLoggesIn, setIsLoggesIn] = useState(!!token);

  const usersData = useSelector((state) => {
    return state.UsersReducer;
  });

  useEffect(() => {
    if (token && !isLoggesIn) {
      // Fetch User Profile
      dispatch(getUserProfile({}));
    }
  }, [token]);

  useEffect(() => {
    if (
      usersData &&
      usersData.userDetails &&
      token &&
      usersData.userDetails.email &&
      !usersData.userDetails.city &&
      !UNAUTH_ROUTES.includes(router.pathname)
    ) {
      // Redirect to profile
      router.replace({
        pathname: EDIT_PROFILE,
      });
    }
  }, [usersData]);

  useEffect(() => {
    if (usersData && usersData.token) {
      cookieCutter.set("token", usersData.token, {
        expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
      });
      setTimeout(() => {
        setToken(usersData.token);
        setIsLoggesIn(true);
      }, 1000);
    } else if (!usersData.isLogin && !usersData.token && usersData.logout) {
      cookieCutter.set("token", cookieCutter.get("token"), {
        expires: new Date(0),
      });
      setToken(null);
      setIsLoggesIn(false);
    }
  }, [usersData]);

  return isLoggesIn;
};
