import { HOST } from "./routes";

export const FACEBOOK_APP_ID = "153554940088686";

export const languageList = [
  {
    code: "en",
    label: "English (EN)",
    shortLabel: "EN",
    hrefLang: "en_US",
    href: `${HOST}/en`,
  },
  {
    code: "zh",
    label: "简体中文 (CN)",
    shortLabel: "CN",
    hrefLang: "zh-Hans",
    href: `${HOST}/zh`,
  },
  {
    code: "de",
    label: "Deutsch (DE)",
    shortLabel: "DE",
    hrefLang: "de",
    href: `${HOST}/de`,
  },
  {
    code: "fr",
    label: "français (FR)",
    shortLabel: "FR",
    hrefLang: "fr",
    href: `${HOST}/fr`,
  },
  // {
  //   code: "ru",
  //   label: "русский (RU)",
  //   shortLabel: "RU",
  //   hrefLang: "ru",
  //   href: `${HOST}/ru`,
  // },
  // {
  //   code: "ja",
  //   label: "日本語 (にほんご)",
  //   shortLabel: "JA",
  //   hrefLang: "ja",
  //   href: `${HOST}/ja`,
  // },
];

export const languageAlternates = [
  {
    hrefLang: "x-default",
    href: `${HOST}`,
  },
  ...languageList.map((list) => ({
    hrefLang: list.hrefLang,
    href: list.href,
  })),
];

export const generateAlternateLanguages = (url) => {
  return languageAlternates.map((language) => ({
    ...language,
    href: `${language.href}${url}`,
  }));
};

export const robotsProps = {
  notranslate: true,
};

export const additionalMetaTags = [
  {
    property: "application-name",
    content: "findSportsPartner",
  },
  {
    httpEquiv: "x-ua-compatible",
    content: "IE=edge; chrome=1",
  },
];

export const facebook = {
  appId: FACEBOOK_APP_ID,
};

export const twitter = {
  handle: "@handle",
  site: "@site",
  cardType: "summary_large_image",
};

export const additionalLinkTags = [
  {
    rel: "icon",
    href: `${HOST}/favicon.ico`,
  },
  {
    rel: "apple-touch-icon",
    href: `${HOST}/touch-icon-ipad.jpg`,
    sizes: "76x76",
  },
  {
    rel: "manifest",
    href: `${HOST}/manifest.json`,
  },
];

export const title = {
  home: "find_sports_partner_seo_home_title",
  login: "login_page_title",
  register: "register_page_title",
  "forgot-password": "forgot_password_page_title",
  countryHome: "country_find_sports_partner_seo_home_title",
  countryCategory: "country_find_sports_partner_by_category_seo_home_title",
  postProperty: "post_ads_for_free_seo_title",
  cookiePolicy: "find_sports_partner_seo_cookie_policy_title",
  privacyPolicy: "find_sports_partner_seo_policy_policy_title",
  termsOfUse: "find_sports_partner_seo_terms_of_use_title",
};

export const description = {
  home: "find_sports_partner_seo_home_description",
  login: "login_page_description",
  register: "register_page_description",
  "forgot-password": "forgot_password_page_description",
  countryHome: "country_find_sports_partner_seo_home_description",
  countryCategory:
    "country_find_sports_partner_by_category_seo_home_description",
  postProperty: "post_ads_for_free_seo_description",
  cookiePolicy: "find_sports_partner_seo_cookie_policy_description",
  privacyPolicy: "find_sports_partner_seo_policy_policy_description",
  termsOfUse: "find_sports_partner_seo_terms_of_use_description",
};

export const keywords = {
  home: "find_sports_partner_seo_home_keywords",
  countryHome: "country_find_sports_partner_seo_home_keywords",
  countryCategory: "country_find_sports_partner_by_category_seo_home_keywords",
  postProperty: "post_ads_for_free_seo_keywords",
};
