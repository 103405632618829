import React, { Fragment, useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import useTranslation from "next-translate/useTranslation";
import Typography from "@material-ui/core/Typography";
import dynamic from "next/dynamic";
import Divider from "@material-ui/core/Divider";
import useStyles from "./style";

const SocialLoginComponent = dynamic(() => import("../SocialLogin"), {
  ssr: false,
});

function SignInForm({ isLoading }) {
  const classes = useStyles();
  const { t } = useTranslation("common");

  return (
    <Fragment>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        {t("login_to_website")}
      </Typography>
      <br />
      <Grid container xs={12}>
        <SocialLoginComponent isLoading={isLoading} />
        <Divider light={false} className={classes.divider} />
      </Grid>
    </Fragment>
  );
}

export default SignInForm;
