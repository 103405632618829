import TextField from "@material-ui/core/TextField";
import { useField } from "formik";
import _ from "lodash";
import useStyles from "./style";

const renderTextField = ({ label, ...props }) => {
  const classes = useStyles();
  const [field, meta] = useField(props);

  // const debounce = useCallback(
  //   _.debounce((_searchVal) => {
  //     fetchSuggestions(_searchVal);
  //   }, 1000),
  //   []
  // );

  return (
    <TextField
      className={classes.field}
      variant="outlined"
      margin="normal"
      label={label}
      fullWidth
      placeholder={label}
      error={meta.touched && meta.error}
      helperText={meta.touched && meta.error}
      {...field}
      {...props}
    />
  );
};

export default renderTextField;
